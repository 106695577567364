import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import HeaderSection from '../components/sections/HeaderSection';

import TeamfotoImage from '../components/images/Teamfoto';

import RocketIcon from '../images/icons/rocket.svg';
import LightBulbIcon from '../images/icons/light-bulb.svg';

import LogoImage from '../images/logo.svg';
import CityLinkProjectBox from '../components/sections/project-boxes/CityLink';
import WohinDuWillstProjectBox from '../components/sections/project-boxes/WohinDuWillst';
import AreYouInterested from '../components/sections/AreYouInterested';

/* eslint-disable react/prop-types */
const ServiceCard = ({
  title,
  icon,
  description,
}) => (
    <div className="flex flex-col h-full relative box">
      <img src={LogoImage} className="absolute pin-r pin-t w-6 -mr-3 -mt-3" alt="Hochzehn Logo" />
      <div className="flex flex-row">
        <img src={icon} className="w-12 h-12 my-1" alt="Icon" />
      </div>
      <div className="flex flex-row">
        <h3 classnmae="m0">{title}</h3>
      </div>
      <div className="flex flex-row mt-2">
        <p>{description}</p>
      </div>
    </div>
  );
/* eslint-enable react/prop-types */

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[]} />
    <HeaderSection>
      <h1>
        Let's create
        <span className="text-brand"> great products</span>
        <br />
        together.
      </h1>
    </HeaderSection>

    <div>
      <div className="flex flex-row relative">
        <div className="flex flex-col items-start max-w-md">
          <h2>Wir entwickeln digitale Geschäftsmodelle & Produkte.</h2>
        </div>
      </div>

      <div className="flex flex-row">
        <div className="flex flex-col flex-grow">
          <p className="mt-8 max-w">
            Als Technologiepartner auf Augenhöhe entwickeln wir gemeinsam mit unseren Kunden großartige digitale Lösungen.
            </p>
        </div>
      </div>

      <div className="flex flex-wrap justify-center mt-8">
        <div className="w-full md:w-1/2 pl-0 p-3  ">
          <ServiceCard
            icon={LightBulbIcon}
            title="Konzeption"
            description="Damit es nicht bei der guten Idee bleibt."
          />
        </div>
        <div className="w-full md:w-1/2 pl-0 md:pl-3 p-3">
          <ServiceCard
            icon={RocketIcon}
            title="Entwicklung"
            description="Agile Umsetzung, vom MVP bis zur komplexen Enterprise Anwendung."
          />
        </div>
      </div>

      <div className="flex flex-row justify-center">
        <Link className="button button-animate mt-8" to="services">
          unsere Leistungen
          <FontAwesomeIcon icon={faArrowRight} />
        </Link>
      </div>
    </div>

    <div className="flex flex-row justify-center">
      <div className="flex flex-col w-full">
        <h2>Unsere Projekte</h2>
        <div className="flex flex-row w-full flex-wrap mt-8">
          <WohinDuWillstProjectBox />
          <CityLinkProjectBox />
        </div>

        <div className="flex flex-row mt-6 justify-center">
          <Link to="/works" className="button button-animate mt-3">
            weitere Projekte ansehen
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>

      </div>
    </div>

    <div className="flex flex-row flex-wrap">
      <div className="flex flex-col w-full md:w-1/2">
        <div className="flex flex-row">
          <div className="flex flex-col">
            <span className="text-xl font-bold">Wir sind</span>
            <h2>
              hochzehn
            </h2>
            <div className="w-4/5 h-3 border-b-2 border-brand" />
          </div>
        </div>
        <p className="mt-8 sm:max-w-xs">
          Unser Team besteht aus Experten für innovative Technologien, agile Entwicklung und digitale Themen.
          Erfahre mehr über uns und unsere Philosophie.
        </p>

        <div className="flex flex-row justify-center sm:justify-start mt-8">
          <div className="flex flex-col w-full sm:w-3/5">
            <Link className="button button-outlined animate-icon-in justify-center text-center w-full" to="/about-us">
              <div>
                über uns
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </Link>
            <Link className="button button-outlined animate-icon-in justify-center w-full mt-3" to="/career">
              <div>
                join us
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full md:w-1/2 flex-grow mt-8 md:mt-0">
        <TeamfotoImage />
      </div>
    </div>

    <AreYouInterested />
  </Layout>
);

export default IndexPage;
